<script setup lang="ts">
import { ref, toRef, onUnmounted, inject, watch, computed, onMounted, nextTick } from "vue";
import type { Ref } from "vue";
import { $Popup } from "@/types";
import { AssetTypeGenerator, AssetTypeLightTower, AssetTypeFuelTruck } from "@/db";
import type { AssetTableItem, Coordinator } from '@/db';
import LightTowerIcon from '@/components/icons/LightTowerIcon.vue';
import FuelTruckIcon from '@/components/icons/FuelTruckIcon.vue';
import GeneratorIcon from '@/components/icons/GeneratorIcon.vue';
import { DateTime } from 'luxon';
import { mapSymbol } from "@/types";
import type { ServicingResponse } from "@/types";
import { useTimeAgo, until } from "@vueuse/core";

const props = defineProps<{
	selected?: AssetTableItem | null,
	rn: Ref<Coordinator>
}>();

const pls = ref(null)
const where = toRef(props, "rn");

const popup = inject($Popup);
const loading = ref(false);
const servicedDurationAgo = ref("");

const map                 = inject(mapSymbol)!;
// false = unavaiable, null = error or loading.
const servicing = ref<ServicingResponse>({} as ServicingResponse)
const lastServicedAt = computed(() => {
	const servicedAt = servicing?.value?.serviced_at
	if (servicedAt?.seconds) {

		const result = DateTime.fromSeconds(servicedAt?.seconds)
		servicedDurationAgo.value = useTimeAgo(result.toJSDate()).value
		return result
	}

	return null
})

const isServiceDue = computed(() => {
	return (servicing?.value?.next_service_hours - servicing?.value?.estimated_service_read) < 0
})

const calculatedServiceProgress = computed(() => {
	const servicedAt = servicing?.value
	if (servicedAt?.estimated_service_read) {
		return ((servicedAt.estimated_service_read - servicedAt.service_meter_read) / servicedAt.servicing_interval) * 100
	}

	return null
})

const selected = toRef(props, "selected");
const mounted = ref(false);

(async() => {
	await until(mounted).toBe(true);
	popup.addTo(map.value)
})();

watch(selected,
	async() => {
		await until(mounted).toBe(true);
		if (selected.value) {
			// @ts-ignore
			servicing.value = null;
			loading.value = true;

			console.log(`selectedAsset changed to ${selected.value.hardware_id}`)

			if (selected.value.type === "TYPE_FUEL_TRUCK") {
				loading.value = false;
				servicing.value = null
				await nextTick(() => {
					popup.setHTML(pls.value.innerHTML)
				});
				// Avoid querying servicing information until feature is ready.
				return
			}

			fetch("/servicing", {
				method: "POST",
				credentials: "include",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					"asset_id": selected.value.id,
					"imei": selected.value.imei,
				})
			}).then(response => response.json() as any as ServicingResponse)
			.then(data => {
				servicing.value = data
				loading.value = false;
				nextTick(() => {
					popup.setHTML(pls.value.innerHTML)
				});
			}).catch((reason) => {
				loading.value = false;
				servicing.value = null
				nextTick(() => {
					popup.setHTML(pls.value.innerHTML)
				});
			})
		} else {
			// @ts-ignore
			servicing.value = null;
			loading.value = false;
			console.log('selectedAsset nulled!')
		}
	},
	{ immediate: true },
)
const latestSpeed = ref(0);


watch(where.value, async() => {
	await until(mounted).toBe(true);

	latestSpeed.value = where.value.speed ?? 0;
	popup.setLngLat([parseFloat(where.value.longitude), parseFloat(where.value.latitude)])
	await nextTick(() => popup.setHTML(pls.value.innerHTML))
}, {immediate: true})

onUnmounted(() => {
	popup.remove()
	//yrse
});

onMounted(() => {
	mounted.value = true;
})
</script>

<template>
	<div ref="pls" style="position:absolute;display:none;width:350px;">
		<div class="wrapper">
			<ProgressSpinner v-if="loading" />

			<div class="flex pb-3 popout" v-if="selected">
				<div class="flex w-8rem align-items-center justify-content-center">
					<LightTowerIcon v-if="selected.type === AssetTypeLightTower" class="w-3rem h-3rem" style="font-size: 2rem"></LightTowerIcon>
					<FuelTruckIcon v-if="selected.type === AssetTypeFuelTruck" class="w-3rem h-3rem" style="font-size: 2rem"></FuelTruckIcon>
					<GeneratorIcon v-if="selected.type === AssetTypeGenerator" class="w-3rem h-3rem" style="font-size: 2rem"></GeneratorIcon>
					<i v-if="false" class="pi pi-globe w-3rem h-3rem" style="font-size: 2rem;"></i>
				</div>
				<div class="flex flex-column row-gap-0 w-12rem align-items-start justify-content-start">
					<span class="text-900 text-xl font-medium">{{ selected.name }}</span>
					<span v-if="servicing?.make" class="text-900">{{ servicing?.make }} {{ servicing?.model }}</span>
					<span v-if="false" class="loc"></span>
				</div>
				<div class="flex flex-column w-12rem align-items-end justify-content-end" style="color:#FFF;">
					<!-- Container? -->
					<div class="flex-row flex">
						<!--
						<Tag severity="success" value="On hire"></Tag>
						<Tag class="ml-1" severity="success" value="On not"></Tag>
						-->
					</div>
				</div>
			</div>

			<!--
			<ProgressBar :value="latestSpeed" > {{ latestSpeed }}kmph </ProgressBar>
			-->

			<div class="w-full pl-3 lg:pl-0 flex align-items-end mt-5 lg:mt-0 border-top-1 pt-3" v-if="servicing">
				<ul class="list-none p-0 m-0 w-full">
					<li class="flex justify-content-between mb-2" v-if="servicing?.last_meter_read && servicing?.estimated_service_read">
						<span class="s-hed loc">Estimated Meter Read</span>
						<span class="text-900 font-medium">{{ $n(servicing?.estimated_service_read, 'time') }}s</span>
					</li>
					<li class="flex justify-content-between mb-2" v-if="servicing?.last_meter_read">
						<span class="s-hed loc">Last Meter Read</span>
						<span class="text-700 font-medium">{{ DateTime.fromSeconds(servicing?.last_meter_read_at.seconds).toLocaleString(DateTime.DATE_SHORT) }}</span>
						<span class="text-900 font-medium">{{ $n(servicing?.last_meter_read, 'time') }}s</span>
					</li>
					<li class="flex justify-content-between mb-2" v-if="lastServicedAt" v-tooltip="lastServicedAt?.toLocaleString(DateTime.DATE_MED)">
						<span class="s-hed loc">Last Serviced</span>
						<span class="text-900 font-medium">{{ servicedDurationAgo }} @ {{ $n(servicing.service_meter_read, 'time') }}s</span>
					</li>
					<li class="flex justify-content-between mb-2" v-if="servicing?.next_service_hours && isServiceDue === false">
						<span class="s-hed loc">Next Service In</span>
						<span class="text-900 font-medium">{{ $n(servicing.next_service_hours - servicing?.estimated_service_read, 'time') }}s ({{ $n(servicing.next_service_hours, 'time') }})</span>
					</li>
					<li class="flex justify-content-between mb-2" v-if="servicing?.next_service_hours && isServiceDue === true">
						<span class="s-hed loc">Next Service</span>
						<span class="text-900 font-medium"><Tag severity="danger">Overdue by {{ $n(servicing?.estimated_service_read - servicing.next_service_hours, 'time') }}s</Tag>  {{ $n(servicing.next_service_hours, 'time') }}</span>
					</li>

					<li v-if="!servicing?.next_service_hours">
						<Message class="mb-0" :sticky="true" severity="warn" :closable="false">Syrinx Log entries are missing. Unable to calculate servicing information</Message>
					</li>
				</ul>
			</div>
			<Message v-if="false && !servicing" :sticky="true" severity="warn" :closable="false">Unable to find matching Syrinx fleet item</Message>

			<ProgressBar v-if="servicing?.service_meter_read" id="seviceprogress" :value="calculatedServiceProgress" :showValue="false"></ProgressBar>
		</div>
	</div>
</template>

<style lang="scss" scoped>
/*Hackjob to force Primevue components into a non-V-DOM context*/
.p-message-enter-from {
	opacity: 100 !important;
}

.popout .pi {
	color: #FFF !important;
	font-size: 3em !important;
}
.s-hed {
	color: rgba(255, 255, 255, 0.70) !important;
}

.border-top-1 {
	border-color: #0B213F;
}

.wrapper {
	font-size: 1rem !important;
	color: #FFF !important;
	width: 350px;
}

#seviceprogress.p-progressbar {
	height:12px;
}
.loc {
	color: rgba(255, 255, 255, 0.50);
}
.p-tag.p-tag-success {
	color: #FFF !important;
	background-color: #22C55E !important;
}
</style>
