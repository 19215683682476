<script setup lang="ts">
import { computed, watch, ref, onBeforeUnmount, onMounted, shallowRef, toRef } from "vue";
import AppTopbar from './AppTopbar.vue';
import AppSidebar from './AppSidebar.vue';
import AppConfig from './AppConfig.vue';
import AppProfileSidebar from './AppProfileSidebar.vue';
import AppBreadCrumb from './AppBreadcrumb.vue';
import { useLayout } from '@/layout/composables/layout';
import PremiAir from '@/assets/company/PremiAir-400.png';

const { layoutConfig, layoutState, isSidebarActive, isDarkTheme } = useLayout();

const props = defineProps<{
    menuMode?: string,
	isPadded?: boolean,
}>();

const outsideClickListener = ref(null);
const sidebarRef = shallowRef(null);
const topbarRef = ref(null);
const appConfig = ref(null);
const currentMenuMode = toRef(props, "menuMode");

watch(currentMenuMode, (newV) => {
    layoutConfig.menuMode.value = newV ? newV : 'static';
}, {immediate: true})

watch(isSidebarActive, (newVal) => {
    if (newVal) {
        bindOutsideClickListener();
    } else {
        unbindOutsideClickListener();
    }
});

onBeforeUnmount(() => {
    unbindOutsideClickListener();
});

const containerClass = computed(() => {
    return {
        'layout-light': layoutConfig.colorScheme.value === 'light',
        'layout-dim': layoutConfig.colorScheme.value === 'dim',
        'layout-dark': layoutConfig.colorScheme.value === 'dark',
        'layout-colorscheme-menu': layoutConfig.menuTheme.value === 'colorScheme',
        'layout-primarycolor-menu': layoutConfig.menuTheme.value === 'primaryColor',
        'layout-transparent-menu': layoutConfig.menuTheme.value === 'transparent',
        'layout-overlay': layoutConfig.menuMode.value === 'overlay',
        'layout-static': layoutConfig.menuMode.value === 'static',
        'layout-slim': layoutConfig.menuMode.value === 'slim',
        'layout-horizontal': layoutConfig.menuMode.value === 'horizontal',
        'layout-static-inactive': layoutState.staticMenuDesktopInactive.value && layoutConfig.menuMode.value === 'static',
        'layout-overlay-active': layoutState.overlayMenuActive.value,
        'layout-mobile-active': layoutState.staticMenuMobileActive.value,
        'p-input-filled': layoutConfig.inputStyle.value === 'filled',
        'p-ripple-disabled': !layoutConfig.ripple.value
    };
});

const bindOutsideClickListener = () => {
    if (!outsideClickListener.value) {
        outsideClickListener.value = (event) => {
            if (isOutsideClicked(event)) {
                layoutState.overlayMenuActive.value = false;
                layoutState.overlaySubmenuActive.value = false;
                layoutState.staticMenuMobileActive.value = false;
                layoutState.menuHoverActive.value = false;
            }
        };
        document.addEventListener('click', outsideClickListener.value);
    }
};
const unbindOutsideClickListener = () => {
    if (outsideClickListener.value) {
        document.removeEventListener('click', outsideClickListener.value);
        outsideClickListener.value = null;
    }
};
const isOutsideClicked = (event) => {
    const sidebarEl = sidebarRef?.value?.$el;
    const topbarEl = topbarRef?.value?.$el?.querySelector('.topbar-menubutton');

    if (!sidebarEl || !topbarEl) {
        return
    }

    return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target));
};

onMounted(() => {
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        layoutConfig.colorScheme.value = 'dim';
    }
    //layoutConfig.menuMode.value = 'horizontal';
})

const showCompanyLogo = window.location.hostname == 'premiair.trackthis.com.au' && false;
</script>

<template>
    <div :class="['layout-container', { ...containerClass }]" class="">
		<AppSidebar ref="sidebarRef" />
		<AppTopbar class="p-1" ref="topbarRef" />

		<div class="layout-content-wrapper flex flex-column" :class="{'no-padding': isPadded}">

            <AppBreadCrumb class="content-breadcrumb flex-none"></AppBreadCrumb>
            <div class="layout-content flex-1 flex flex-column">
              <slot />
				<img id="tenant-logo" v-if="showCompanyLogo" :src="PremiAir" />

            </div>
        </div>

        <AppProfileSidebar />
        <AppConfig ref="appConfig" />

        <Toast></Toast>
        <div class="layout-mask"></div>
    </div>
</template>

<style lang="scss">
.layout-content-wrapper.no-padding {
	padding: 0 !important;
	position: absolute;
	width: 100%;
	height: calc(100vh - 50px);
}

#tenant-logo {
	position: absolute;
	right: 0;
	bottom: 25px;
	margin: 0 15px 15px 0;
}
</style>
